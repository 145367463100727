<template>
  <b-card>
    <b-card-header>
      {{ $t('confront.warehouse.check.confrontationGuidesPackages') }}
    </b-card-header>
    <b-card-body>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <!--Guia-->
          <validation-provider
            #default="validationContext"
            name="Guia"
            rules="required"
          >
            <b-form-group
              :label="$t('confront.warehouse.check.guide')"
              label-for="guia"
            >
              <b-form-input
                id="guia"
                v-model="awb"
                :disabled="completedConfront"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!--Bultos-->
          <validation-provider
            #default="validationContext"
            name="Bulto"
            rules="required"
          >
            <b-form-group
              :label="$t('confront.warehouse.check.package')"
              :label-for="$t('confront.warehouse.check.package')"
            >
              <b-form-input
                id="bulto"
                v-model="pack"
                :disabled="completedConfront"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!--Checked-->
          <validation-provider
            #default="validationContext"
            name="Etiquetado"
            rules="required"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="taged"
                :state="getValidationState(validationContext)"
                :unchecked-value="''"
                checked="S"
              >
                {{ $t('confront.warehouse.check.labelled') }}
              </b-form-checkbox>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ $t('confront.warehouse.check.requiredLabelPackage') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-button
            v-if="!completedConfront"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            type="submit"
            :disabled="completedConfront"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            {{ $t('confront.warehouse.check.confrontBulge') }}
          </b-button>
          <b-button
            v-if="completedConfront"
            variant="success"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            type="submit"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onConfront"
          >
            {{ $t('confront.warehouse.check.presentConfronts') }}
          </b-button>
        </b-form>
      </validation-observer>
      <app-collapse>
        <app-collapse-item :title="$t('confront.warehouse.check.details')">
          <b-table
            ref="refTable"
            :items="confront.relacionDocumentoOperaciones"
            :fields="tableFields"
            responsive
          >
            <template #cell(estado)="data">
              <div
                v-if="data.item.confrontado"
                class="text-success"
              >
                {{ $t('confront.warehouse.check.confronted') }}
              </div>
              <div
                v-if="!data.item.confrontado"
                class="text-warning"
              >
                {{ $t('classification.list.classificationPending') }}
              </div>
            </template>
          </b-table>
        </app-collapse-item>
      </app-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BTable,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import common from '@/libs/common'
import confrontService from '@/services/confront.service'
import router from '@/router'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BTable,

    AppCollapse,
    AppCollapseItem,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    /* Services */
    const { updateConfront } = confrontService()
    const { toastSuccess, toastError, toastInfo } = common()
    /* Data */
    const awb = ref('')
    const pack = ref('')
    const taged = ref('')
    const confront = ref(router.currentRoute.params.confront)

    const tableFields = [
      { key: 'numeroPedimento', label: i18n.t('confront.warehouse.check.pedimentoNumber') },
      { key: 'claveDocumento', label: i18n.t('confront.warehouse.check.documentKey') },
      { key: 'numeroRemesa', label: i18n.t('confront.warehouse.check.remesaNumber') },
      { key: 'guiaHouse', label: i18n.t('confront.warehouse.check.labelHouse') },
      { key: 'guiaMaster', label: i18n.t('confront.warehouse.check.labelMaster') },
      { key: 'bulto', label: i18n.t('confront.warehouse.check.labelPackage') },
      { key: 'peso', label: i18n.t('confront.warehouse.check.weight') },
      { key: 'estado', label: i18n.t('confront.warehouse.check.status') },
    ]
    const refTable = ref(null)

    const completedConfront = ref(false)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation({})

    const onSubmit = () => {
      const records = confront.value.relacionDocumentoOperaciones
      // Check valid package and guide
      const typedPacks = records.filter(f => (f.guiaHouse === awb.value || f.guiaMaster === awb.value) && f.bulto.toString() === pack.value)
      if (typedPacks.length === 0) toastError(i18n.t('confront.warehouse.check.wrongGuide'))

      // Check confronted packs
      const peendingPackages = typedPacks.filter(f => !f.confrontado)
      if (typedPacks.length > 0 && peendingPackages.length === 0) toastInfo(i18n.t('confront.warehouse.check.lumpPreviouslyConfronted'))

      // Confront valid package
      if (typedPacks.length > 0 && peendingPackages.length > 0) {
        const [confrontPackge] = peendingPackages
        confrontPackge.confrontado = true
        toastSuccess(i18n.t('confront.warehouse.check.lumpConfronted'))
      }

      // if (typedPack) {
      //   if (typedPack.confrontado) {
      //     toastInfo(i18n.t('confront.warehouse.check.lumpPreviouslyConfronted'))
      //   } else {
      //     typedPack.confrontado = true
      //     toastSuccess(i18n.t('confront.warehouse.check.lumpConfronted'))
      //   }
      // } else {
      //   toastError(i18n.t('confront.warehouse.check.wrongGuide'))
      // }

      completedConfront.value = records.filter(f => f.confrontado).length === records.length
      refTable.value.refresh()

      taged.value = ''
      awb.value = ''
      pack.value = ''
      refFormObserver.value.reset()
    }

    const onConfront = () => {
      updateConfront(confront.value, () => {
        router.push({ name: 'apps-confronts-warehouse-list' })
      })
    }

    return {
      // Data
      awb,
      pack,
      taged,
      confront,

      completedConfront,

      // Form Validation
      required,
      refFormObserver,
      getValidationState,
      resetForm,

      onSubmit,
      onConfront,

      tableFields,
      refTable,
    }
  },
}
</script>

<style>

</style>
